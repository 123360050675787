import React, { useEffect, useState, useRef } from "react";
import Comments from "./Comments";
function Info() {
  return (
    <>
      <p>
        This dictionary is generated with gpt-4o and gpt-3.5-turbo.
        <br />
      </p>
      <hr />
      <p>
        이 사전은 gpt-4o과 gpt-3.5-turbo로 생성된 사전 입니다.
        <br />
      </p>
      <Comments></Comments>
    </>
  );
}

export default Info;
