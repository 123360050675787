import "./App.css";
import React, { useEffect, useState } from "react";

function createBlankWordObject(str) {
  return {
    word: str,
    meanings: [
      {
        partOfSpeech: "",
        partOfSpeechSub: [],
        definition: "",
        examples: [
          {
            sentence: " ",
            translation: " ",
          },
        ],
      },
    ],
  };
}

function App() {
  const [data, setData] = useState({
    list: [createBlankWordObject("type words to search")],
  });
  const [wordSearched, setWordSearched] = useState("");

  // useEffect(() => {
  //   fetch("database/dictionary_db.json")
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => setData(data))
  //     .catch((error) => {
  //       console.error("Error fetching the data:", error);
  //       setData({
  //         list: [createBlankWordObject("type words to search")],
  //       });
  //     });
  // }, []);

  useEffect(() => {
    fetch(
      "https://raw.githubusercontent.com/gongboo/generated-korean-english-dictionary-db/main/dictionary_db.json"
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => setData(data))
      .catch((error) => {
        console.error("Error fetching the data:", error);
        setData({
          list: [createBlankWordObject("type words to search")],
        });
      });
  }, []);

  function handleInputChange(e) {
    setWordSearched(e);
  }

  function searchDictByWord(word) {
    for (let word_obj of data.list) {
      if (word_obj.word === word) {
        return word_obj;
      }
    }
    if ("" === word) {
      return createBlankWordObject("type words to search");
    }
    return createBlankWordObject("no word found");
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "500px",
        }}
      >
        <div>
          <h1 style={{ display: "inline" }}>
            Generated English-Korean Dictionary
          </h1>{" "}
          <a
            className="material-symbols-outlined"
            style={{ display: "inline", textDecoration: "none", color: "gray" }}
            href="/info"
          >
            help
          </a>
        </div>
        <br />
        <div
          style={{
            width: "100%",
          }}
        >
          <input
            onChange={(e) => {
              handleInputChange(e.target.value);
            }}
            value={wordSearched}
            style={{ fontSize: 20, width: "100%" }}
          ></input>
          <Word item={searchDictByWord(wordSearched)}></Word>
        </div>
      </div>
    </div>
  );
}

function Word(props) {
  return (
    <div>
      <h2>{props.item.word}</h2>
      {props.item.meanings.map((meaning) => (
        <Meaning
          partOfSpeech={meaning.partOfSpeech}
          partOfSpeechSub={meaning.partOfSpeechSub}
          definition={meaning.definition}
          examples={meaning.examples}
        />
      ))}
    </div>
  );
}

function Meaning(props) {
  //partOfSpeech, partOfSpeechSub, definition, examples
  return (
    <div>
      <div style={{ display: "inline-block" }}>{props.definition}</div>{" "}
      <div style={{ display: "inline-block", color: "gray", fontWeight: 300 }}>
        {props.partOfSpeech}{" "}
        {props.partOfSpeechSub.length !== 0
          ? "(" + props.partOfSpeechSub.join(",") + ")"
          : null}
      </div>
      {props.examples[0].sentence.length !== 1 ? (
        <Example examples={props.examples} />
      ) : null}
      <hr />
    </div>
  );
}

function Example(props) {
  return (
    <div>
      {props.examples.map((example) => (
        <div>
          <div>- {example.sentence}</div>
          <div>- {example.translation}</div>
        </div>
      ))}
    </div>
  );
}

export default App;
